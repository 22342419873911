<template>
    <div data-comp="Checkbox">
        <label class="flex items-middle">
            <slot/>

            <input
                type="checkbox"
                ref="checkboxElement"
                :value="value"
                :id="id"
                v-model="refProps.checked"
                :disabled="disabled"
            />

            <span v-if="props.showLabel" class="ml-2" >{{ value }}</span>

            <span class="checkmark" />
        </label>
    </div>
</template>

<script setup>
    import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
    import { reactify } from '@/utils';

    const thisComp = getCurrentInstance();

    const emit = defineEmits([]);

    const props = defineProps({
        checked: {type: [Array, Boolean], default: false},
        disabled: {type: Boolean, default: false},
        value: {type: [String, Number], default: null },
        id: {type: [String, Number], default: null},
        showLabel: {type: Boolean, default: true},
        groupChecked: {type: Array},
        groupValues: {type: Array},
    });

    const refProps = reactify(thisComp);

    const isChecked = () => props.checked.includes(props.value);
    const checkboxElement = ref(null);
    const hasCheckedAll = computed(() => props.groupChecked
        && props.groupValues.length === props.groupChecked.length
        && props.groupChecked.length > 0);
    const isGroupCheckbox = Boolean(props.groupChecked) && Boolean(props.groupValues);

    const defaultCheck = (doCheck, dispatchEvent = true) => {
        if (doCheck) {
            refProps.checked = [...props.checked, props.value];
        } else {
            refProps.checked = props.checked.filter((e) => e !== props.value);
        }

        if (dispatchEvent && checkboxElement.value) {
            checkboxElement.value.dispatchEvent(new Event('change'));
        }
    };

    const setCheckAll = (doCheck) => {
        if (doCheck) {
            refProps.groupChecked = [...props.groupValues];
        } else {
            refProps.groupChecked = [];
        }
    };

    // eslint-disable-next-line no-unused-vars
    const setCheck = (doCheck) => {
        if (!isGroupCheckbox) {
            defaultCheck(doCheck);
        } else {
            setCheckAll(doCheck);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const toggleCheck = () => {
        setCheck(!isChecked());
    };

    const toggleCheckAll = () => {
        setCheckAll(!hasCheckedAll.value);
    };

    const changeHandle = () => {
        if (isGroupCheckbox) {
            toggleCheckAll();
        }
    };

    watch(hasCheckedAll, (value) => {
        if (isGroupCheckbox) {
            defaultCheck(value, false);
        }
    });

    onMounted(() => {
        if (checkboxElement.value) {
            checkboxElement.value.addEventListener('change', changeHandle);
        }
    });

    defineExpose({
        isChecked,
        setCheck,
        toggleCheck,
        hasCheckedAll,
    });
</script>

<style lang="scss" scoped>
  [data-comp="Checkbox"] {
    label {
      position: relative;
      padding-left: 20px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      @apply rounded bg-gray-400;
    }

    /* On mouse-over, add a grey background color */
    label:hover input ~ .checkmark {
      @apply bg-gray-500 transition ease-in-out duration-200;
    }

    /* When the checkbox is checked, add a blue background */
    label input:checked + .checkmark {
      /* background-color: #2196F3; */
      @apply bg-blue-400;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    label input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    label .checkmark:after {
      left: 6px;
      width: 8px;
      height: 15px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    label input:disabled + .checkmark {
      @apply bg-gray cursor-not-allowed;
    }
  }
</style>
